<template>
  <div class="list">
    <list-search
      @onSearch="onSearch"
      @clearSearch="clearSearch"
      :childData="childData"
    ></list-search>
    <list-menu @buttonClick="buttonClick" :menuData="menudata"></list-menu>
    <div class="tableList">
      <div class="table">
        <el-table :data="tableData" ref="tabledeatailData">
          <el-table-column
            prop="supplierCode"
            label="供应商编码"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="supplierName"
            label="供应商名称"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="auditYear"
            label="审核年度"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="auditDesc"
            label="审核说明"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="createDate"
            label="创建时间"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column label="操作" width="">
            <template #default="scope">
              <el-button
                type="text"
                size="mini"
                @click="deleterole(scope.$index, scope.row)"
                >删除</el-button
              >
              <el-button type="text" size="mini" @click="grade(scope.row)"
                >打分</el-button
              >
              <!-- <el-button
                type="text"
                size="mini"
                @click="Uploadreport(scope.row)"
                >上传报告</el-button
              > -->
            </template>
          </el-table-column>
        </el-table>
        <div class="tablepage" v-if="tableData && tableData.length > 0">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
    <!-- <dialog-box ref="addDialog"   :dialogShow="DialogShow" @handleClose='handleClose' @confirmAdd= "confirmAdd"  title="新建" componentName="createdDialog"
      :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{label:'确认',action:'confirmAdd', type:'primary', size:'mini'}]">
    </dialog-box> -->
    <el-dialog v-model="DialogShow" title="新建">
      <el-form
        class="content bascform"
        ref="form"
        :model="form"
        label-width="100px"
        :rules="rules"
      >
        <div class="col col4">
            <el-form-item label="供应商名称" prop="supplierName">
            <el-select style="width:100%" filterable remote
              :remote-method="requestSupplierOption"
              :loading="supplierLoading"
              @change="supChange"
               v-model="form.supplierName">
              <el-option v-for="(item) in supplierOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="供应商编码" prop="supplierCode">
            <el-input disabled v-model="form.supplierCode"></el-input>
          </el-form-item>
          <!-- <el-form-item label="供应商名称" prop="supplierName">
            <el-input v-model="form.supplierName"></el-input>
          </el-form-item> -->
          <el-form-item label="审核年度" prop="auditYear">
            <el-input v-model="form.auditYear"></el-input>
          </el-form-item>
          <el-form-item label="审核说明" prop="auditDesc">
            <el-input v-model="form.auditDesc"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="handleClose">取消</el-button>
          <el-button type="primary" @click="confirmAdd">确认</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { SearchList, SearchData } from './js/auditScore'
import { request, requestForm } from '@/assets/js/http.js'
export default {
  components: { ...Component.components },
  name: 'AuditScore',
  data () {
    var pageSize = parseInt((document.documentElement.clientHeight - 280) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      rules: {
        supplierCode: [
          { required: true, message: '请输入供应商编码', trigger: 'blur' }
        ],
        supplierName: [
          { required: true, message: '请输入供应商名称', trigger: 'blur' }
        ],
        auditYear: [
          { required: true, message: '请输入审核年度', trigger: 'blur' }
        ],
        auditDesc: [
          { required: true, message: '请输入审核说明', trigger: 'blur' }
        ]
      },
      form: {
        supplierCode: '',
        supplierName: '',
        auditYear: '',
        auditDesc: ''
      },
      tableData: [],
      menudata: [
        {
          label: '+新建',
          action: 'created',
          id: 1
        }
      ],
      DialogShow: false,
      EditDialogShow: false,
      showFormData: {},
      paginationData: {},
      total: 0,
      pageNum: 1,
      pageSize: pageSize,
      childData: { searchList: SearchList, searchData: SearchData },
      supplierOptions: [],
      supplierLoading: false,
    }
  },
  created () {
    this.auditScoreList()
  },
  methods: {
    supChange (data) {
      for (const item of this.supplierOptions) {
        if (item.value === data) {
          this.form.supplierCode = item.erpCode
          this.form.supplierName = item.value
        }
      }
    },
    requestSupplierOption (val) {
      this.supplierLoading = true
      this.supplierOptions = []
      requestForm('/api/supplier/basicData/getForSelect', 'post', { supplier: val }).then((Response) => {
        if (Response.code === '200') {
          for (const item of Response.data) {
            this.supplierOptions.push({
              key: `${item.name}`,
              label: `${item.name}`,
              value: `${item.name}`,
              erpCode: `${item.erpCode}`
            })
          }
        }
        this.supplierLoading = false
      })
    },
    Uploadreport () {
      // 上传报告
    },
    deleterole (index, data) {
      this.$confirm('此操作将删除记录,是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          request('/api/quality/auditScore/delete/' + data.id, 'delete').then(
            (response) => {
              if (response.code === '200') {
                this.$message({
                  message: '删除成功',
                  type: 'success'
                })
                this.auditScoreList()
              }
            }
          )
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    grade (data) {
      this.$router.push({
        params: { viewName: 'CheckScoreDetail' },
        query: {
          id: data.id
        }
      })
    },
    buttonClick () {
      this.form = {
        supplierCode: '',
        supplierName: '',
        auditYear: '',
        auditDesc: ''
      }
      this.DialogShow = true
    },
    confirmAdd () {
        console.log('111', this.form)
      // 新增确认按钮
      // var form = this.$refs.addDialog.$refs.content.$data.form
      // console.log('111', form)
      this.$refs.form.validate((valid) => {
        if (!valid) {
          this.$message({
            message: '请输入全部的必选项',
            type: 'warning'
          })
        } else {
          request('/api/quality/auditScore/save', 'post', this.form).then(
            (Response) => {
              if (Response.code === '200') {
                this.$message({
                  message: '新增成功',
                  type: 'success'
                })
                this.DialogShow = false
                this.auditScoreList()
              }
            }
          )
        }
      })
    },
    blockup () {},
    edit () {
      this.EditDialogShow = true
    },
    scoreDetailBtn (data) {
      // 评分细项
      this.$router.push({
        params: { viewName: 'ScoreDetail' },
        query: {
          id: data.id
        }
      })
    },
    handleClose () {
      this.DialogShow = false
      this.EditDialogShow = false
    },
    auditScoreList () {
      var pageSize = parseInt(
        (document.documentElement.clientHeight - 330) / 42
      )
      if (pageSize < 10) {
        pageSize = 10
      }
      const obj = {
        supplier: this.childData.searchData.supplier,
        auditYear: this.childData.searchData.auditYear
      }
      request(
        '/api/quality/auditScore/list?' +
          'pageNum=' +
          this.pageNum +
          '&' +
          'pageSize=' +
          pageSize,
        'GET',
        obj
      ).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.records
          this.total = res.data.total
        }
      })
    },
    tableAction: function (index, data, clickBtn) {
      request(`/api/quality/data/getById/${data.id}`, 'get').then((res) => {
        if (res.code === '200') {
          this.showFormData = res.data
          this.showDialogShow = true
        }
      })
    },
    onSearch (data) {
      this.auditScoreList()
    },
    clearSearch (data) {
      this.auditScoreList()
    },
    // 每页显示多少条
    handleSizeChange (val) {
      this.pageSize = val
      this.auditScoreList()
    },
    // 当前第几页
    handleCurrentChange (val) {
      this.pageNum = val
      this.auditScoreList()
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/css/tableList.scss";
</style>
